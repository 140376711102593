import React from "react";
import { graphql } from "gatsby";
import gql from 'graphql-tag';
import withPreview from '../../components/common/withPreview';

const PostTemplate = (props) => {
  /**
   * Determine if we're looking at a preview or live page.
   */

  const postData = props.preview ?
    props.preview.postBy.revisions.nodes[0] : // grab the first revision
    props.data.wpgraphql.post
  const {
    title,
    content,
  } = postData;
  return (
    <>
        <h1>{title}</h1>
        <div className="post-content" dangerouslySetInnerHTML={{ __html: content }} />
    </>
  )
}

export const pageQuery = graphql`
    query($id: String) {
        page: wpSitePage(id: { eq: $id }) {
            title
            content
            # excerpt # No longer supported in WP v6
            formattedDate: date(formatString: "MMM D, YYYY")
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(height: 260, placeholder: NONE, layout: FIXED)
                        }
                    }
                }
            }
            # toc { hasTableOfContents }
            # translated {
            #     title
            #     # excerpt # No longer supported in WP v6
            #     formattedDate: date(formatString: "MMM D, YYYY")
            #     locale {
            #         locale
            #     }
            #     toc { hasTableOfContents }
            # }
        }
        site {
            siteMetadata {
                socialAlt
                siteUrl
                title
            }
        }
    }
`

//executes on page query
const PAGE_PREVIEW_QUERY = gql`
  query getPreview($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      title
      content
    }
  }
`;

//executes on post query
const POST_PREVIEW_QUERY = gql`
  query getPreview($id: ID!) {
    post(id: $id, idType: DATABASE_ID) {
      title
      content
    }
  }
`;
export default withPreview({ preview: PAGE_PREVIEW_QUERY, postPreview: POST_PREVIEW_QUERY })(PostTemplate);